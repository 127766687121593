<template>
  <div class="container df">
    <div class="twoMenubox">
      <div class="item" :class="current == idx ? 'active' : ''" @click="menuHandle(i, idx)" v-for="(i, idx) in menu" :key="idx">
        <img :src="current == idx ? i.icon : i.activeicon" alt="" />
        <div>{{ i.title }}</div>
      </div>
    </div>

    <div class="list">
      <video-list ref="liveList" v-if="current == 0" @add="add"></video-list>
      <interaction v-if="current == 1"></interaction>
      <explain v-if="current == 2"></explain>
      <live-list v-if="current == 3"></live-list>
    </div>
  </div>
</template>
<script>
import videoList from "./components/video/videoList.vue";
import liveList from "./components/live/liveList.vue";
import interaction from "./components/interaction/interaction.vue";
import explain from "./components/explain/explain.vue";
import { ipcApiRoute, specialIpcRoute } from "@/api/main";
export default {
  components: {
    liveList,
    interaction,
    explain,
    videoList,
  },
  name: "live",
  data() {
    return {
      current: 0,
      liveId: null,
      showList: true,
      menu: [
        {
          icon: require("@/assets/img/secondMenu/video1.png"),
          activeicon: require("@/assets/img/secondMenu/video.png"),
          title: "直播视频",
          pageName: "video",
          params: {},
        },
        {
          icon: require("@/assets/img/secondMenu/hudong1.png"),
          activeicon: require("@/assets/img/secondMenu/hudong.png"),
          title: "互动话术",
          pageName: "shortVideo",
          params: {},
        },
        {
          icon: require("@/assets/img/secondMenu/jiangjie1.png"),
          activeicon: require("@/assets/img/secondMenu/jiangjie.png"),
          title: "商品讲解",
          pageName: "video",
          params: {},
        },
        {
          icon: require("@/assets/img/secondMenu/fangan1.png"),
          activeicon: require("@/assets/img/secondMenu/fangan.png"),
          title: "直播方案",
          pageName: "shortVideo",
          params: {},
        },
      ],
    };
  },
  mounted() {
    // this.init();
  },
  methods: {
    init() {
      this.$ipc.invoke(ipcApiRoute.openSoftware, "app.exe").then((r) => {
        console.log("resourcePath:", r);
      });
    },
    menuHandle(e, idx) {
      if (this.$store.state.openlive) {
        this.$message.error("请先关闭直播");
        return;
      }
      this.current = e ? idx : 0;
      const linkInfo = this.menu[this.current];
      // this.$router.push({ name: linkInfo.pageName, params: linkInfo.params });
    },
    select(e) {
      this.active = e;
    },
    add(id) {
      this.liveId = id + "";
      this.showList = !this.showList;
      if (this.showList) {
        console.log(123);
        this.$refs.liveList.getlive();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.twoMenubox {
  width: 12%;
  background: #fff;

  .item {
    font-size: 16px !important;
  }
  img {
    width: 18px;
    height: 18px;
  }
}
.list {
  width: 88%;
}
</style>
