<template>
  <div class="list">
    <div v-if="videoList.length > 0" class="mt10" v-infinite-scroll="load">
      <div class="listBox1" v-for="i in videoList" :key="i.id">
        <img :src="i.interaction_image" alt="" />
        <div class="info">
          <div class="tit"><span class="Ttext">互动名称：</span> {{ i.interaction_name }}</div>
          <div>
            <div class="Ttext mb5">互动关键词：</div>
            <div class="df f-w">
              <div v-for="(j, index) in i.keywords" :key="index" class="btn">{{ j }}</div>
            </div>
          </div>
          <div class="mt10">
            <span><span class="Ttext">互动视频数量</span>{{ i.videvo_ids_num }}个</span>
            <span style="margin-left: 20px"><span class="Ttext">创建时间：</span>{{ i.create_time }}</span>
          </div>
        </div>
        <div class="tools" @click.stop="delitem(i)">
          <i class="el-icon-delete"></i>
        </div>
      </div>
    </div>
    <div v-else>
      <myempty text="互动话术"></myempty>
    </div>
  </div>
</template>
<script>
import { goods_query_data, goods_del_data } from "@/api/live.js";
import myempty from "@/components/myempty.vue";
export default {
  components: {
    myempty,
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      videoList: [], //直播互动
      page: 1,
      pageNum: 1,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    load() {
      if (this.page < this.pageNum) {
        this.page++;
        this.getList();
      }
    },
    getList() {
      goods_query_data({
        page: this.page,
        item: 10,
        goods_id: this.id,
        type: 2,
      }).then((res) => {
        if (res.code == "200") {
          if (res.data.list.length > 0) {
            this.videoList.push(...res.data.list);
          }
          this.pageNum = res.data.page_num;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    delitem(i) {
      this.$confirm("是否删除该视频?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((_) => {
          goods_del_data({
            goods_id: this.id,
            type: 2,
            data_id: i.id,
          }).then((res) => {
            if (res.code == "200") {
              this.$message.success(res.msg);
              this.videoList = [];
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch((_) => {});
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-input__inner {
  border: 0;
  color: #939dae;
  background: #f2f3f5;
}

/deep/.el-dialog__body {
  padding: 20px;
}

.back {
  width: 45px;
  height: 45px;
  border-radius: 6px;
  background: #f2f7fe;
  margin-top: 8px;
  line-height: 45px;

  img {
    width: 24px;
    height: 24px;
  }
}
.listBox {
  background: #fff;
  text-align: left;
  width: 99%;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
}

.listBox1 {
  background: #fff;
  width: 100%;
  padding: 15px;
  margin: 0 0 20px;
  border-radius: 2px;
  display: flex;

  .info {
    margin-left: 20px;
    text-align: left;
    width: 86%;

    .tit {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 12px;
    }

    .btn {
      background: #d9edf5;
      padding: 1px 7px;
      color: #4284cf;
      border: 1px solid #4284cf;
      border-radius: 4px;
      margin-right: 6px;
    }
  }

  img {
    width: 90px;
    height: 160px;
    border-radius: 8px;
    overflow: hidden;
  }

  .tools {
    position: relative;
    z-index: 99;
    width: 27px;
    height: 27px;
    color: #ff5733;
    line-height: 27px;
    background: #fff;
    border: 1px solid #c8dfff;
    border-radius: 4px;
    top: 0px;
    left: 15px;
  }
}

.listBox1:last-child {
  margin-bottom: 0;
}

.twoMenubox {
  display: flex;
  justify-content: space-between;
  padding-top: 0;

  .menu {
    display: flex;
    align-items: center;
    background: #f2f7fe;
    color: #8b9ab4;
    padding: 10px 8px;
    margin: 8px 10px;
    border-radius: 6px;
    justify-content: space-around;
    width: 130px;
    height: 45px;

    img {
      margin-right: -8px;
      width: 16px;
      height: 18px;
    }
  }

  .active {
    color: #fff !important;
    background: #015ce6;
    margin: 8px 10px;
    border-radius: 6px;
  }

  img {
    width: 18px;
    height: 18px;
  }
}

.vlist {
  .info {
    padding-top: 10px;
  }
  /deep/.el-tabs__header {
    margin: 0 !important;
    padding: 10px;
    background: #fff;
  }

  /deep/.el-tabs__nav-wrap::after {
    height: 0 !important;
  }
}

.ipt {
  width: 32%;
  position: absolute;
  top: 3%;
  right: 2%;

  /deep/.el-input__inner {
    background: #f1f4f9 !important;
  }
}

.dlistBox {
  height: 59vh;
  overflow: scroll;
  padding: 10px 5px 0;
  margin-bottom: -20px;
  .tools {
    border: 0 !important;
    background: transparent;
  }
  .check {
    align-self: center;
  }
  /deep/.el-checkbox-group {
    font-size: 15px !important;
  }
  /deep/.el-checkbox__inner {
    width: 18px;
    height: 18px;
  }
  /deep/.el-checkbox__inner::after {
    height: 10px;
    left: 6px;
  }
  .imgBox {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  }
  .imgBox:nth-child(5n) {
    margin-right: 0;
  }
  .imgBox:nth-child(6n) {
    margin-right: 10px;
  }
}
</style>
