<template>
  <div>
    <div class="con">
      <div class="box left">
        <div class="nav">
          <div class="nitem back" @click="back">
            <img src="~@/assets/img/back.png" alt="" />
          </div>
          <div class="nitem tabidx">
            <img src="~@/assets/img/add/people.png" alt="" />
            <div class="mt5">数字人</div>
          </div>
        </div>
        <div v-if="figureList.length > 0" style="overflow: scroll">
          <div class="df f-w list">
            <div v-for="(item, idx) in figureList" :key="idx" @click="getItem(item, idx)" :class="index == idx ? 'choose' : ''" class="item">
              <img :src="item.cover" object-fit="cover" alt="" />
              <div class="desc">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div v-else style="margin: 0 auto">
          <myempty text="形象"></myempty>
        </div>
      </div>

      <div class="box center">
        <img :src="activeInfo.cover" alt="" />
      </div>

      <div class="right">
        <div class="top df" style="border-radius: 10px; margin-bottom: 10px">
          <div :class="isact == 1 ? 'act' : ''" @click="isact = 1">配音生成</div>
          <div
            :class="isact == 2 ? 'act' : ''"
            @click="
              isact = 2;
              getsound();
            "
          >
            配音列表
          </div>
        </div>

        <div v-show="isact == 1">
          <div class="box1" style="height: 300px">
            <!--  maxlength="300" -->
            <el-input refs="desc" type="textarea" resize="none" ref="inputRef" placeholder="请输入文案话术进行合成" v-model="form.desc" @blur="descBlur" @change="descChange">
            </el-input>
            <div class="tool">
              <el-popover placement="top-start" width="160" trigger="hover" content="一个⏱️为0.5秒停顿">
                <el-button slot="reference" size="small" @click="addText">添加间隔</el-button>
              </el-popover>
              <div :style="[{ color: length > 1000 ? '#F56C6C' : '#547b9c' }]">{{ length }}/1000字</div>
            </div>
          </div>
          <div class="box1 box2">
            <div class="tit">其他设置</div>
            <div class="form">
              <el-form ref="form" :model="form" label-width="57px">
                <el-form-item label="分类">
                  <el-select v-model="form.sort" clearable placeholder="请选择" @change="sortChange">
                    <el-option v-for="i in sortList" :label="i.name" :value="i.id" :key="i.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="音色">
                  <el-select v-model="form.timbre" placeholder="请选择" @change="timbreChange">
                    <el-option v-for="i in timbreList" :label="i.name" :value="i.id" :key="i.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="语速">
                  <div style="width: 93%">
                    <el-slider v-model="form.pace" :min="-5" :max="5" show-tooltip></el-slider>
                  </div>
                </el-form-item>
                <el-form-item label="音调">
                  <div style="width: 93%">
                    <el-slider v-model="form.tone" :min="-5" :max="5" show-tooltip></el-slider>
                  </div>
                </el-form-item>

                <el-form-item>
                  <el-button type="primary" @click="playMp3">试听</el-button>
                  <el-button type="primary" @click="craftAudio">文字转配音</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>

        <div v-show="isact == 2">
          <div class="box1 dfc" style="padding: 10px">
            <el-input style="width: 70%; margin-right: 5px" placeholder="请输入上传文件的文件名称" v-model="name"></el-input>
            <el-button type="info" icon="el-icon-upload2" size="mini" @click="confirm">上传</el-button>
          </div>
          <div class="box1" style="height: 690px; margin-top: 10px">
            <el-table :data="tableData" highlight-current-row @current-change="handleCurrentChange" style="width: 100%; background: #f5f7fe" height="93%">
              <el-table-column show-overflow-tooltip prop="title" label="音频名称"></el-table-column>
              <el-table-column fixed="right" label="操作" width="70">
                <template slot-scope="scope">
                  <div class="df jc-sb">
                    <i v-if="!scope.row.isPlay" style="font-size: 19px; color: #67c23a" class="el-icon-video-play" @click.stop="playTableAudio(scope.row, scope.$index)"></i>
                    <i v-else style="font-size: 19px; color: #67c23a" class="el-icon-video-pause" @click.stop="pauseTableAudio(scope.$index)"></i>
                    <i style="font-size: 19px; color: #f56c6c" class="el-icon-delete" @click.stop="del_sound(scope.row)"></i>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination layout="prev, pager, next" :current-page="page" :total="total * 10" @current-change="currentChange"></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <el-button type="primary" @click="craftVideo">立即生成视频</el-button>
    </div>
    <input type="file" ref="fileInput" accept="audio/*" @change="handleFileUpload" style="display: none" />
  </div>
</template>
<script>
import { craftVideo, get_image_list } from "@/api/video.js";
import { get_cat, gettimbre, craftAudio, getsound, upload_mp3, submits_sound, del_sound } from "@/api/audio.js";
import myempty from "@/components/myempty.vue";
import { countAndReplace } from "@/utils/tools";

export default {
  name: "createVideo",
  components: {
    myempty,
  },
  data() {
    return {
      form: {
        pace: 0,
        tone: 0,
        sort: "",
        timbre: "",
        desc: "",
      },
      tabidx: 0,
      index: 0,
      length: 0,
      isact: 1,
      total: 1,
      page: 1,
      ipt: "",
      descCount: 0,
      name: "",
      timbreUrl: "",
      value: "",
      textCursor: "",
      visible: false,
      uploadFile: false,
      isPlay: false,
      disabled: false,
      audio: null,
      activetimbre: {},
      activeInfo: {},
      activeAudio: {},
      videoList: [],
      figureList: [],
      imageList: [],
      sortList: [],
      timbreList: [],
      tableData: [],
    };
  },
  mounted() {
    this.getFigure();
    this.get_cat();
    this.getsound();
  },
  beforeDestroy() {
    if (this.audio) this.audio.pause();
  },
  destroyed() {},
  methods: {
    back() {
      this.$emit("back");
    },
    choose(i) {
      this.tabidx = i;
    },
    descBlur() {
      this.textCursor = this.$refs.inputRef.$el.children[0].selectionStart;
      console.log(1, this.textCursor, this.$refs.inputRef.$el.children[0]);
    },
    addText() {
      // this.form.desc += "⏱";
      this.form.desc = this.form.desc.substr(0, this.textCursor) + "⏱" + this.form.desc.substr(this.textCursor);
    },
    confirm() {
      if (!this.name) {
        this.$message.error("请输入文件名再上传");
        return;
      }
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const loading = this.$loading({
        lock: true,
        text: "上传音频中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      console.log("even", event);
      const file = event.target.files[0];

      const formData = new FormData();
      formData.append("file", file);
      formData.append("token", localStorage.getItem("token"));
      formData.append("name", this.name);

      submits_sound(formData).then((res) => {
        loading.close();
        if (res.code == "200") {
          this.$message.success(res.msg);
          this.name = "";
          this.getsound();

          // submits_sound({
          //   url: res.data.url,
          //   name: this.name,
          // }).then((res) => {
          //   if (res.code == "200") {
          //     this.$message.success(res.msg);
          //     this.name = "";
          //     this.getsound();
          //   } else {
          //     this.$message.error(res.msg);
          //   }
          // });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getFigure() {
      get_image_list().then((res) => {
        if (res.code == "200") {
          this.figureList = res.data.list;
          this.activeInfo = this.figureList[0];
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    get_cat() {
      get_cat().then((res) => {
        if (res.code == "200") {
          this.sortList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    del_sound(i) {
      del_sound({
        sound_id: i.id,
      }).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
          this.getsound();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getsound() {
      getsound({
        item: 10,
        page: this.page,
      }).then((res) => {
        if (res.code == "200") {
          this.total = res.data.page_num;
          if (this.page == 1) {
            this.tableData = res.data.list;
          } else {
            this.tableData = this.tableData.concat(res.data.list);
          }
          this.tableData.map((i) => {
            i.isPlay = false;
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    descChange(e) {
      let arr = this.form.desc.split("");
      let a1 = arr.filter((i) => i != "⏱");
      this.disabled = a1.length >= 1000 ? true : false;
      this.length = a1.length;
      if (a1.length > 1000) {
        this.$message.error("话术文字超出1000字！");
      }
    },

    handlePaste(event) {
      console.log("onPaste", event.clipboardData.getData("text/plain"));
    },

    sortChange(e) {
      console.log(e, 11);
      gettimbre({ cat_id: e }).then((res) => {
        if (res.code == "200") {
          this.timbreList = [];
          this.timbreList.push(...res.data);
          this.form.timbre = "";
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    timbreChange(e) {
      console.log(e, 11);
      this.timbreList.map((i) => {
        if (i.id == e) this.activetimbre = i;
      });
    },
    playMp3() {
      if (!this.activetimbre.id) {
        this.$message.warning("请选择要试听的音色！");
        return;
      }
      this.audio = new Audio(this.activetimbre.url); // 创建音频对象
      this.audio.play(); // 播放
    },
    playTableAudio(e, idx) {
      console.log(e, idx);
      this.tableData[idx].isPlay = true;
      let arr = JSON.parse(JSON.stringify(this.tableData));
      this.tableData = [];
      this.tableData.push(...arr);
      console.log(this.tableData);
      this.audio = new Audio(e.url); // 创建音频对象
      this.audio.play(); // 播放
      this.audio.addEventListener("ended", () => {
        console.log("播放完毕");
        this.pauseTableAudio(idx);
      });
    },
    pauseTableAudio(idx) {
      this.tableData[idx].isPlay = false;
      this.audio.pause();
      let arr = JSON.parse(JSON.stringify(this.tableData));
      this.tableData = [];
      this.tableData.push(...arr);
    },
    craftAudio() {
      if (!this.form.desc) {
        this.$message.warning("请输入文案进行合成音频！");
        return;
      }
      if (this.length > 1000) {
        this.$message.warning("请输入1000字以内的文案！");
        return;
      }
      if (!this.form.timbre) {
        this.$message.warning("请选择音色进行合成音频！");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "正在合成音频",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      craftAudio({
        timbre_id: this.form.timbre,
        text: countAndReplace(this.form.desc),
        speech_rate: this.form.pace * 100,
        pitch_rate: this.form.tone * 100,
      }).then((res) => {
        loading.close();
        if (res.code == "200") {
          console.log(res.data);
          this.$message.success(res.msg);
          this.getsound();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    craftVideo() {
      if (!this.activeAudio) {
        this.$message.warning("请选择已生成的音频进行合成视频！");
        return;
      }
      if (!this.activeInfo.id) {
        this.$message.warning("请选择形象进行合成视频！");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "正在合成视频",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      craftVideo({
        image_id: this.activeInfo.id,
        sound_id: this.activeAudio.id,
        type_s: 2,
      }).then((res) => {
        loading.close();
        if (res.code == "200") {
          this.$message.success(res.msg);
          // setTimeout(() => {
          //   this.back();
          // }, 800);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    handleCurrentChange(e) {
      this.activeAudio = e;
    },
    currentChange(e) {
      this.page = e;
      this.getsound();
    },
    getItem(e, idx) {
      this.activeInfo = e;
      this.index = idx;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-textarea__inner {
  padding-bottom: 40px;
}
/deep/.el-textarea .el-input__count {
  background: #f3f5fd !important;
}
/deep/.el-table__body tr.current-row > td.el-table__cell {
  background: #80aaff !important;
}
.el-input__inner {
  border: 0px !important;
}
.con {
  height: 87vh;
  background: #d6dff9;
  overflow: scroll;
  padding: 16px 13px 10px;
  display: flex;
  justify-content: space-between;

  .box {
    background: #f5f7fe;
    border-radius: 10px;
    height: 100%;
  }

  .left {
    width: 26%;
    display: flex;
    .nav {
      height: 100%;
      border-right: 2px solid #d7e0ed;

      .nitem {
        background: #fefeff;
        border-radius: 10px;
        padding: 12px 6px;
        margin: 20px 12px;
        font-size: 14px;
        width: 65px;

        img {
          width: 30px;
          height: 30px;
        }
      }
      .tabidx {
        border: 2px solid #4f81ff;
      }
    }

    .list {
      padding: 20px 10px 15px 20px;

      .choose {
        border: 2px solid #4f81ff;
        height: 172px;
      }
    }
  }
  .center {
    margin: 0 12px;
    width: 468px;
    overflow: hidden;

    img {
      width: 468px;
      height: 816px;
    }
  }
  .right {
    width: 26%;
    .box1 {
      border-radius: 10px;
      overflow: hidden;
      color: #547b9c;
      font-size: 14px;
      background: #f4f5fe;

      .tool {
        position: relative;
        top: -38px;
        left: 10px;
        display: flex;
        justify-content: space-between;
        width: 335px;
        align-items: center;
      }
    }
    .top {
      justify-content: space-around;
      padding: 10px;
      background-color: #fff;
    }
    .act {
      color: #0362ee;
    }

    .tit {
      padding: 20px 12px;
    }

    .box2 {
      margin-top: 10px;
      text-align: left;

      .form {
        padding: 0 0 42px;
        height: 40vh;
        /deep/.el-select .el-input__inner:focus,
        /deep/.el-select .el-input__inner {
          border-color: #409eff;
          border: 0 !important;
        }
        /deep/.el-select > .el-input {
          width: 120%;
        }
        /deep/.el-slider__bar {
          background-color: #0362ee !important;
        }
      }
    }
    /deep/.el-pagination {
      padding: 10px !important;
    }
  }
}

.bottom {
  background: #fff;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;

  /deep/.el-button {
    padding: 12px 50px !important;
    background: #347deb !important;
    border-radius: 10px !important;
  }
}

/deep/.el-textarea__inner {
  background: #f3f5fd !important;
  border: 0 !important;
  height: 269px;
  padding: 10px 15px;
}
/deep/.el-select {
  width: 78% !important;
}

/deep/.el-textarea {
  min-height: 300px !important;
}

.item {
  margin-right: 15px;
  margin-bottom: 10px;
  background: #fff;
  border-radius: 5px;
  justify-content: space-between;
  height: 218px;
  width: 112px;
  overflow: hidden;
}

.item .desc {
  padding: 10px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.item .tools {
  position: relative;
  z-index: 99;
  width: 27px;
  height: 27px;
  line-height: 27px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #c8dfff;
  top: -310px;
  left: 135px;
}

.item img {
  width: 108px;
  height: 172px;
  border-radius: 5px 5px 0 0;
}

.item:last-child,
.item:nth-child(2n) {
  margin-right: 0;
}
</style>
