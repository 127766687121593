<template>
  <div class="list">
    <div class="df f-w" v-if="videoList.length > 0" v-infinite-scroll="load" infinite-scroll-distance="50">
      <div v-for="item in videoList" :key="item.id" @click="getItem(item)" class="imgBox">
        <img :src="item.video_image" object-fit="cover" alt="" />
        <div class="desc">
          {{ item.title }}
          <div><i class="el-icon-time"></i>{{ item.create_time }}</div>
        </div>
        <div class="tools" style="color: #ff5733" @click.stop="delitem(item)">
          <i class="el-icon-delete"></i>
        </div>
      </div>
    </div>
    <div v-else>
      <myempty text="视频"></myempty>
    </div>
  </div>
</template>
<script>
import { goods_query_data, goods_del_data, goods_add_data } from "@/api/live.js";
import myempty from "@/components/myempty.vue";
export default {
  components: {
    myempty,
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      videoList: [], //商品视频
      page: 1,
      pageNum: 1,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    handleSelect() {
      console.log(this.ipt);
    },
    load() {
      if (this.page < this.pageNum) {
        this.page++;
        this.getList();
      }
    },
    getList() {
      goods_query_data({
        page: this.page,
        item: 10,
        goods_id: this.id,
        type: 1,
      }).then((res) => {
        if (res.code == "200") {
          if (res.data.list.length > 0) {
            this.videoList.push(...res.data.list);
          }
          this.pageNum = res.data.page_num;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    delitem(i) {
      this.$confirm("是否删除该视频?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((_) => {
          goods_del_data({
            goods_id: this.id,
            type: 1,
            data_id: i.id,
          }).then((res) => {
            if (res.code == "200") {
              this.$message.success(res.msg);
              this.videoList = [];
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch((_) => {});
    },
  },
};
</script>
<style lang="less" scoped>
.imgBox {
  margin-right: 14px;
}
.imgBox:nth-child(6n) {
  margin-right: 15px;
}
.imgBox:nth-child(7n) {
  margin-right: 0px;
}
</style>
