<template>
  <div class="container bg">
    <div v-if="showlist">
      <div class="df jc-sb bg-white head">
        <div class="df">
          <div class="dfc mr50">
            <el-input v-model="minFormdata.videoName" placeholder="请输入商品讲解名称"></el-input>
          </div>
          <div class="df">
            <el-button plain type="primary" @click="getlist(false)">搜索</el-button>
            <el-button plain @click="clear">清理</el-button>
          </div>
        </div>
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="(dialogVisible = true), (type = 'add')">商品讲解</el-button>
      </div>

      <div class="box">
        <div v-if="videoList.length > 0" class="df f-w jc-sb plr20">
          <div class="listBox item" v-for="(i, idx) in videoList" :key="idx" @click="(activeInfo = i), edit()">
            <img src="~@/assets/img/shop.png" alt="" />
            <div class="info">
              <div class="tit"><span class="Ttext">讲解名称：</span> {{ i.goods_name }}</div>
              <div class="mt8"><span class="Ttext">讲解视频数量</span>{{ i.videvo_ids_num }}个</div>
              <div class="mt8"><span class="Ttext">互动话术数量</span>{{ i.interaction_ids_num }}个</div>
              <div class="mt8"><span class="Ttext">创建时间：</span>{{ i.create_time }}</div>
            </div>
            <div class="tools" @click.stop="activeInfo = i">
              <el-dropdown trigger="click" placement="bottom" @command="handleClick">
                <span class="el-dropdown-link">
                  <i class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <!-- <el-dropdown-item command="edit" icon="el-icon-edit">编辑</el-dropdown-item> -->
                  <el-dropdown-item command="rename" icon="el-icon-edit-outline">重命名</el-dropdown-item>
                  <el-dropdown-item command="card" icon="el-icon-collection-tag">卡片序号</el-dropdown-item>
                  <el-dropdown-item command="del" icon="el-icon-delete">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
        <div v-else>
          <myempty text="商品讲解"></myempty>
        </div>
      </div>

      <el-dialog :title="title" :visible.sync="dialogVisible" width="32%" :destroy-on-close="true">
        <el-input v-show="!showCard" v-model="ipt" placeholder="请输入商品讲解名称"></el-input>
        <el-input v-show="showCard" v-model="ipt" placeholder="请输入商品卡片id" type="number"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button
            @click="
              dialogVisible = false;
              ipt = '';
            "
            >取 消</el-button
          >
          <el-button type="primary" v-show="!showCard" @click="addItem">提 交</el-button>
          <el-button type="primary" v-show="showCard" @click="setItem">保 存</el-button>
        </span>
      </el-dialog>
    </div>
    <div v-else>
      <create ref="create" :id="activeInfo.id" @back="back" :info="activeInfo"></create>
    </div>
  </div>
</template>
<script>
import { goods_get_list, goods_del, goods_edit_name, goods_add, edit_text_card } from "@/api/live.js";
import myempty from "@/components/myempty.vue";
import create from "./create.vue";
export default {
  name: "explain",
  components: {
    myempty,
    create,
  },
  data() {
    return {
      minFormdata: {
        videoStatus: "",
        videoName: "",
      },
      showlist: true,
      visible: false,
      dialogVisible: false,
      innerVisible: false,
      openLive: false,
      showStatus: false,
      disabled: false,
      isopen: false,
      fullscreenLoading: false,
      israndom: false,
      isAddr: false,
      isRoomId: false,
      showCard: false,
      ipt: "",
      type: "add",
      title: "添加商品讲解",
      player: null, // 用来存储当前 video
      inputLive: "",
      liveAddr: "",
      roomId: "", //直播房间号
      activeInfo: {},
      activeI: {},
      chooseRoom: "",
      liveInfo: {
        livelist: [],
        keywordList: [],
      },
      downloadList: [],
      value: "",
      isPlay: false,
      videoList: [],
    };
  },
  mounted() {
    this.getlist();
  },
  methods: {
    back() {
      this.showlist = true;
      this.getlist();
    },
    addItem() {
      if (!this.ipt) {
        this.$message.error("请输入商品讲解名称");
        return;
      }

      switch (this.type) {
        case "rename":
          goods_edit_name({
            goods_id: this.activeInfo.id,
            goods_name: this.ipt,
          }).then((res) => {
            if (res.code == "200") {
              this.dialogVisible = false;
              this.getlist();
            } else {
              this.$message.error(res.msg);
            }
          });
          break;
        default:
          console.log("add");
          goods_add({
            goods_name: this.ipt,
          }).then((res) => {
            console.log(res);
            if (res.code == "200") {
              this.dialogVisible = false;
              this.getlist(true);
            } else {
              this.$message.error(res.msg);
            }
          });
          break;
      }
      this.ipt = "";
      this.title = "添加商品讲解";
    },
    edit() {
      this.showlist = false;
      // this.$refs.create.id = this.activeInfo.id;
      // this.$refs.create.init();
    },
    clear() {
      this.minFormdata = {
        videoName: "",
      };
      this.getlist();
    },
    setItem() {
      let params = {
        goods_id: this.activeInfo.id,
        cord_id: this.ipt,
      };
      edit_text_card(params).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
          this.dialogVisible = false;
          this.getlist();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getlist(r = false) {
      let params = {
        so: this.minFormdata.videoName || "",
      };
      goods_get_list(params).then((res) => {
        if (res.code == "200") {
          this.videoList = res.data.list;
          if (r) {
            this.activeInfo = this.videoList[this.videoList.length - 1];
            this.edit();
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClick(command) {
      this.type = command;
      switch (command) {
        case "edit":
          // this.showlist = false;
          this.edit();
          break;
        case "card":
          this.showCard = true;
          this.title = "设置商品卡片id";
          this.dialogVisible = true;
          this.ipt = this.activeInfo.cord_id;
          break;
        case "rename":
          this.title = "修改商品讲解名称";
          this.dialogVisible = true;
          this.ipt = this.activeInfo.goods_name;
          break;
        case "del":
          this.$confirm("此操作将永久删除该视频, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then((_) => {
              goods_del({
                goods_id: this.activeInfo.id,
              }).then((res) => {
                if (res.code == "200") {
                  this.$message.success(res.msg);
                  this.getlist();
                } else {
                  this.$message.error(res.msg);
                }
              });
            })
            .catch((_) => {});
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-input__inner {
  border: 0;
  color: #939dae;
  background: #f2f3f5;
}
.listBox {
  background: #fff;
  width: 49%;
  padding: 20px;
  display: flex;
  margin-bottom: 15px;
  border-radius: 8px;

  .info {
    margin-left: 20px;
    text-align: left;
    width: 86%;
    padding: 10px;

    .tit {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 14px;
    }
  }

  img {
    width: 99px;
    height: 146px;
    border-radius: 8px;
    overflow: hidden;
  }
}

// .listBox:last-child {
//   margin-bottom: 0;
// }

/deep/.el-dialog__header {
  padding: 20px 20px 10px;
  text-align: left;
  font-weight: bold;
}

.el-select {
  width: 100%;
}

/deep/.el-dialog {
  border-radius: 8px;
}

.item {
  .desc {
    padding: 10px;
    text-align: left;
  }
  .tools {
    position: relative;
    z-index: 99;
    width: 32px;
    height: 32px;
    line-height: 32px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #c8dfff;
    top: 0px;
    left: 5px;
  }
}

.title {
  text-align: left;
  font-weight: bold;
  font-size: 16px;
}

.imgBg {
  img {
    width: 175px;
    height: 275px;
  }
}

.info {
  padding-top: 5px;

  /deep/.el-pagination {
    padding: 5px 5px 0 !important;
  }
}

.voxBox {
  .btns {
    padding: 15px;
    background: #f1f4f9;
    height: 245px;
    overflow: scroll;

    /deep/.el-button {
      padding: 10px !important;
      margin-bottom: 10px;
      margin-left: 0 !important;
    }

    img {
      width: 35px;
      height: 35px;
      border-radius: 50px;
      margin-right: 7px;
    }
  }

  .bottom {
    padding: 10px 20px;
  }
}
</style>
