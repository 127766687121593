<template>
  <div class="bg" style="height: 92vh">
    <el-container>
      <el-header class="bg-white" style="line-height: 60px">
        <div class="dfc">
          <div class="back" @click="back">
            <img src="~@/assets/img/back1.png" alt="" />
          </div>
          <el-button type="primary" @click="submit">提交</el-button>
        </div>
      </el-header>
      <el-main>
        <div class="box">
          <div class="listBox">
            <div class="Ttext mb10">互动话术</div>
            <div style="width: 320px">
              <el-input v-model="form.name" placeholder="请输入互动话术标题"></el-input>
            </div>
          </div>

          <div class="listBox">
            <div class="Ttext mb10">触发关键词</div>
            <div class="df mb10" style="width: 360px" v-for="(i, idx) in keywordList" :key="idx">
              <el-input v-model="keywordList[idx]" placeholder="请输入互动话术标题"></el-input>
              <el-button style="margin-left: 20px" type="danger" icon="el-icon-delete" @click="delKey(idx)">删除</el-button>
            </div>
            <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addKey">添加关键词</el-button>
          </div>

          <div>
            <div class="listBox dfc" style="padding: 10px">
              <div class="Ttext">互动视频</div>
              <el-button type="primary" icon="el-icon-circle-plus-outline" @click="showAdd = true">添加视频</el-button>
            </div>
            <div>
              <div class="df f-w" v-if="chooseList.length > 0">
                <div v-for="(item, idx) in chooseList" :key="idx" @click="getItem(item)" class="imgBox">
                  <img :src="item.video_image" object-fit="cover" alt="" />
                  <div class="desc">
                    {{ item.title }}
                    <div><i class="el-icon-time"></i>{{ item.create_time }}</div>
                  </div>
                  <div class="tools" @click.stop="delItem(item)">
                    <i class="el-icon-delete"></i>
                  </div>
                </div>
              </div>
              <div v-else>
                <myempty text="视频"></myempty>
              </div>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
    <el-dialog title="选择视频添加" :visible.sync="showAdd" width="70%" :show-close="false">
      <!-- 搜索框 -->
      <div class="ipt">
        <el-input v-model="search" placeholder="输入关键词搜索视频" suffix-icon="el-icon-search" @blur="handleSelect" @keyup.enter.native="handleSelect"></el-input>
      </div>
      <div v-if="videoList.length > 0" class="dlist">
        <el-checkbox-group v-model="checkList">
          <div class="dialog" v-infinite-scroll="load">
            <div v-for="(i, idx) in videoList" :key="idx" class="listBox1">
              <div class="check mr10">
                <el-checkbox :key="i.id" :label="i.id"><br /></el-checkbox>
              </div>
              <img :src="i.video_image" alt="" />

              <div class="info">
                <div class="tit">{{ i.title }}</div>

                <div>
                  <div>视频形象：{{ i.video_name }}</div>
                  <span style="margin-left: 20px">声音：{{ i.timbre_name }}</span>
                </div>
                <div class="i_bottom">
                  <span>时长：{{ i.times }}</span>
                  <span style="margin-left: 20px">创建时间：{{ i.create_time }}</span>
                </div>
              </div>
            </div>
          </div>
        </el-checkbox-group>
      </div>
      <div v-else>
        <el-empty description="暂无视频"></el-empty>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAdd = false">取 消</el-button>
        <el-button type="primary" @click="addItem">添加</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getVideo } from "@/api/video.js";
import { add, edit, get_one } from "@/api/live.js";
import myempty from "@/components/myempty.vue";
export default {
  components: {
    myempty,
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: {
        name: "",
      },
      search: "",
      ipt: "",
      page: 1,
      pageNum: 1,
      showList: true,
      showAdd: false,
      dialogVisible: false,
      isPlay: false,
      keywordList: [""],
      chooseList: [],
      checkList: [],
      activeInfo: {},
      activeI: {},
      videoList: [],
    };
  },
  mounted() {
    if (this.id != "") {
      this.get_one();
    }
    this.getList();
  },
  methods: {
    back() {
      this.$emit("back");
    },
    addKey() {
      this.keywordList.push("");
    },
    delKey(idx) {
      this.keywordList.splice(idx, 1);
    },
    handleSelect() {
      console.log(11);
    },
    addItem() {
      console.log(this.checkList);
      // this.chooseList = [];
      this.videoList.map((i) => {
        this.checkList.map((j) => {
          if (i.id == j) {
            console.log(i);
            this.chooseList.push(i);
          }
        });
      });
      console.log(this.chooseList);
      this.showAdd = false;
    },
    delItem(item) {
      this.$nextTick(() => {
        this.chooseList.map((i, idx) => {
          if (i.id == item.id) {
            this.chooseList.splice(idx, 1);
          }
        });
      });
    },
    load() {
      if (this.page < this.pageNum) {
        this.page++;
        this.getList();
      }
    },
    getList() {
      getVideo({
        page: this.page,
        item: 10,
        statusa: 3,
        type_s: 2,
      }).then((res) => {
        if (res.code == "200") {
          // this.videoList = res.data.list;
          this.videoList.push(...res.data.list);
          this.pageNum = res.data.page_num;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    get_one(id) {
      get_one({
        interaction_id: this.id,
      }).then((res) => {
        if (res.code == "200") {
          console.log(res);
          this.keywordList = [];
          this.chooseList = [];
          this.form.name = res.data.interaction_name;
          this.keywordList.push(...res.data.keywords);
          this.chooseList.push(...res.data.videvo_data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    submit() {
      let arr = [];
      this.chooseList.map((i) => {
        arr.push(i.id + "");
      });
      if (this.id != "") {
        edit({
          interaction_id: this.id,
          keywords: this.keywordList,
          interaction_name: this.form.name,
          videvo_ids: arr,
        }).then((res) => {
          if (res.code == "200") {
            this.$message.success(res.msg);
            this.id = "";
            setTimeout(() => {
              this.back();
            }, 800);
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        add({
          keywords: this.keywordList,
          interaction_name: this.form.name,
          videvo_ids: arr,
        }).then((res) => {
          if (res.code == "200") {
            this.$message.success(res.msg);
            setTimeout(() => {
              this.back();
            }, 800);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-input__inner {
  border: 0;
  color: #939dae;
  background: #f2f3f5;
}

.back {
  width: 45px;
  height: 45px;
  border-radius: 6px;
  background: #f2f7fe;
  margin-top: 8px;
  line-height: 45px;

  img {
    width: 24px;
    height: 24px;
  }
}
.listBox {
  background: #fff;
  text-align: left;
  width: 99%;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
}

.listBox1 {
  background: #fff;
  width: 99%;
  padding: 15px;
  margin: 0 0 20px;
  display: flex;
  border-bottom: 2px solid #f0f0f0;
  align-items: center;

  .info {
    margin-left: 20px;
    text-align: left;
    width: 86%;

    .tit {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 12px;
    }

    .btn {
      background: #d9edf5;
      padding: 1px 7px;
      color: #4284cf;
      border: 1px solid #4284cf;
      border-radius: 4px;
      margin-right: 6px;
    }
  }

  /deep/.el-checkbox__inner {
    width: 20px !important;
    height: 20px !important;
  }
  /deep/.el-checkbox__inner::after {
    height: 12px !important;
    left: 8px !important;
  }

  img {
    width: 90px;
    height: 130px;
    border-radius: 8px;
    overflow: hidden;
  }
}

.listBox1:last-child {
  margin-bottom: 0;
  border-bottom: 0px;
}

.dlist {
  height: 59vh;
  overflow: scroll;
  padding: 10px 5px 0;
  margin-bottom: -20px;
}

.ipt {
  width: 32%;
  position: absolute;
  top: 3%;
  right: 2%;

  /deep/.el-input__inner {
    background: #f1f4f9 !important;
  }
}
</style>
